<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <ValidationProvider name="Zone" vid="zone_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="zone"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('fertilizerConfig.zone_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="portInfo.zone_id"
                        :options="portZoneList"
                        id="zone_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Port Name(En)" vid="port_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="port_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerConfig.port_name_en')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="zone_name"
                          v-model="portInfo.port_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Port Name (Bn)" vid="port_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="port_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerConfig.port_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="port_name_bn"
                          v-model="portInfo.port_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Contact Person Info (En)" vid="contact_person_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="contact_person_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerConfig.contact_person_name_en')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="port_name_bn"
                          v-model="portInfo.contact_person_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Contact Person Info (bn)" vid="contact_person_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="contact_person_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerConfig.contact_person_name_bn')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="contact_person_name_bn"
                          v-model="portInfo.contact_person_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Contact Person Mobile No" vid="contact_person_mobile_no" rules="digits:11|required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="contact_person_mobile_no"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('fertilizerConfig.contact_person_mobile_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            type="number"
                            id="contact_person_mobile_no"
                            v-model="portInfo.contact_person_mobile_no"
                            placeholder=""
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { portInfoSetupStore, portInfoSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPortInfoData()
      this.portInfo = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      portInfo: {
        port_name: '',
        port_name_bn: '',
        zone_id: 0,
        contact_person_name: '',
        contact_person_name_bn: '',
        contact_person_mobile_no: ''
      }
    }
  },
  computed: {
    portZoneList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portZoneSetupList.filter(item => item.status === 1)
    }
  },
  methods: {
    getPortInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.portInfo.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${portInfoSetupUpdate}/${this.id}`, this.portInfo)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, portInfoSetupStore, this.portInfo)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
